import React from 'react';
import { useQuery, useSession, LoadingPage, getTitle, FormNameProvider, PaginatorWithResult } from '../components';
import { convertQueryToForm, getCurrentQueryFromLocation, convertFormToGraphQl } from '../personal_results/utils';
import { Survey, initialSurvey } from '../entity';
import { gql } from '@apollo/client';
import styles from './PersonalResults.scss';
import Select from 'react-select';
import useReactRouter from 'use-react-router';
import _ from 'lodash';
import searchForm from '../personal_results/SearchForm';
import moment from 'moment';
import { DeleteForm as DeleteButton } from './DeleteEmployeeSurvey';
import useCallbackAfterTask from '../hooks/useCallbackAfterTask';

const SURVEYS = gql`
  query surveys {
    client {
      implementationSurveys {
        id
        createdAt
        surveyEndDate
        surveyForm {
          id
          title
          surveyFormQuestionDisplayCategories {
            id
            categoryTitles {
              id
              language
              shortTitle
            }
          }
        }
      }
    }
  }
`;

const PERSONAL_RESULTS = gql`
  query personalResults($id: ID!, $limit: Int, $page: Int, $search: EmployeeSurveySearchInput) {
    client {
      implementationSurvey(id: $id) {
        id
        createdAt
        surveyEndDate
        surveyForm {
          id
          title
        }
        searchEmployeeSurveys(limit: $limit, page: $page, search: $search) {
          totalCount
          list {
            id
            answerFinished
            isNotable
            officeName
            employmentType
            group1
            group2
            group3
            position
            isProvided
            employee {
              id
              commonId
              staffCode
              firstName
              lastName
            }
            employeeSurveyCalculatedValues {
              id
              shortTitle
              categoryNumber
              value
              level
            }
          }
        }
      }
    }
  }
`;

const additionalParams = { embed: [] };

const searchFormName = 'employeeSurveySearchTop';

const SearchForm = searchForm(searchFormName);

interface ResultsProps {
  selectedSurvey: Survey;
  surveys: Survey[];
}
const Results: React.FC<ResultsProps> = ({ selectedSurvey, surveys }) => {
  const language = 'japanese';
  const { user } = useSession();
  const {
    history,
    location: { search, pathname }
  } = useReactRouter();
  const formValues = convertQueryToForm(getCurrentQueryFromLocation({ search, pathname }, user));
  const variables = { id: selectedSurvey.id, ...convertFormToGraphQl(formValues) };
  const personalResults = useQuery(PERSONAL_RESULTS, { variables, fetchPolicy: 'no-cache' });
  const isPractitioner = user.roleType === 'practitioner';

  const surveyOptions = surveys.map(survey => ({
    label: getTitle(survey),
    value: survey
  }));
  const list =
    (!personalResults.loading && personalResults.data?.client.implementationSurvey.searchEmployeeSurveys.list) || [];
  const totalCount = personalResults.data?.client?.implementationSurvey?.searchEmployeeSurveys?.totalCount;

  useCallbackAfterTask(['send_request'], [personalResults.refetch]);

  return (
    <>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">個人結果</h1>
      </div>
      <div className={styles.contentsWrap}>
        <div className="l-wrap-xl">
          <div className={styles.leftButtons}>
            <Select
              name="SurveyForm"
              className={styles.select}
              simpleValue
              required
              clearable={false}
              value={{ label: getTitle(selectedSurvey), value: selectedSurvey }}
              options={surveyOptions}
              noResultsText={'実施済みのストレスチェックがありません'}
              onChange={(selected: any) => {
                history.push(`/personal_results/${selected?.id}`);
              }}
            />
          </div>
          <SearchForm
            isLoading={personalResults.loading}
            totalCount={totalCount}
            additionalParams={additionalParams}
            selectedSurvey={selectedSurvey}
            employeeSurveyIds={_.map(list, 'id')}
            isPractitioner={isPractitioner}
          />
          <div className={styles.surveyPeriod}>
            実施期間：{moment(selectedSurvey.createdAt).format('YYYY/MM/DD')} から{' '}
            {moment(selectedSurvey.surveyEndDate).format('YYYY/MM/DD')} まで
          </div>
          <div>
            {personalResults.loading ? (
              <LoadingPage />
            ) : (
              <>
                <div className="l-overflow-scroll">
                  <table className={styles.table}>
                    <thead>
                      <tr>
                        <th className={styles.staffCodeColumn}>スタッフコード</th>
                        <th className={styles.nameColumn}>氏名</th>
                        <th className={styles.employmentStatusColumn}>適用事業所</th>
                        <th className={styles.groupColumn}>グループ</th>
                        {selectedSurvey ? (
                          selectedSurvey.surveyForm.surveyFormQuestionDisplayCategories.map(category => (
                            <th key={category.id} className={styles.statusColumn}>
                              <div className={styles.statusHeader}>
                                {_.find(category.categoryTitles, { language: language }).shortTitle}
                              </div>
                            </th>
                          ))
                        ) : (
                          <th></th>
                        )}
                        <th className={styles.statusColumn}>
                          <div className={styles.statusHeader}>総合点数</div>
                        </th>
                        <th className={styles.highStressColumn}>高ストレス</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.length > 0 &&
                        list.map(employeeSurvey => {
                          const isDisplay =
                            (employeeSurvey.isProvided || user.roleType === 'practitioner') &&
                            employeeSurvey.answerFinished;
                          return (
                            <tr
                              key={employeeSurvey.employee.commonId}
                              onClick={() => {
                                if (isDisplay) {
                                  history.push(`/personal_results/details/${employeeSurvey.id}`);
                                }
                              }}
                              className={styles.row + (isDisplay ? ' table-hover' : '')}>
                              <td>{employeeSurvey.employee.staffCode}</td>
                              <td>
                                {employeeSurvey.employee.lastName} {employeeSurvey.employee.firstName}
                              </td>
                              <td>{employeeSurvey.officeName}</td>
                              <td>
                                {_.compact([employeeSurvey.group1, employeeSurvey.group2, employeeSurvey.group3]).map(
                                  (group, index) => (
                                    <span key={index}>
                                      {group}
                                      <br />
                                    </span>
                                  )
                                )}
                              </td>
                              {employeeSurvey.answerFinished && isDisplay ? (
                                <>
                                  {employeeSurvey.employeeSurveyCalculatedValues.map(calculatedValue => (
                                    <td key={calculatedValue.categoryNumber}>{calculatedValue.value}</td>
                                  ))}
                                  <td>
                                    {_.map(employeeSurvey.employeeSurveyCalculatedValues, 'value').reduce(
                                      (accumulator, current) => accumulator + current
                                    )}
                                  </td>
                                  <td className={styles.notable}>{employeeSurvey.isNotable ? '✔' : ''}</td>
                                </>
                              ) : employeeSurvey.answerFinished ? (
                                <td colSpan={selectedSurvey.surveyForm.surveyFormQuestionDisplayCategories.length + 2}>
                                  閲覧権限がありません
                                </td>
                              ) : (
                                <td
                                  colSpan={selectedSurvey.surveyForm.surveyFormQuestionDisplayCategories.length + 2}
                                  className={styles.notFinished}>
                                  未回答
                                </td>
                              )}
                              <td>
                                <div onClick={e => e.stopPropagation()}>
                                  <DeleteButton
                                    selectedSurvey={selectedSurvey}
                                    employeeSurveyId={employeeSurvey.id}
                                    employee={employeeSurvey.employee}
                                    onUpdated={personalResults.refetch}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <FormNameProvider formName={searchFormName}>
                  <PaginatorWithResult count={totalCount} isLoading={personalResults.loading} />
                </FormNameProvider>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const PersonalResults = ({
  match: {
    params: { surveyId }
  }
}) => {
  const { data: fetchedSurveys, loading: loadingSurveys } = useQuery(SURVEYS, { fetchPolicy: 'no-cache' });
  if (loadingSurveys) {
    return <LoadingPage />;
  }

  const surveys = fetchedSurveys?.client?.implementationSurveys || [];
  const selectedSurvey = _.find(surveys, { id: surveyId }) || surveys[0] || initialSurvey;

  if (surveys.length === 0) {
    return (
      <div className="l-wrap-xs u-pt100">
        <div className="l-box-message">
          <p>ストレスチェックがありません</p>
        </div>
      </div>
    );
  } else {
    return <Results selectedSurvey={selectedSurvey} surveys={surveys} />;
  }
};
